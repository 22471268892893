/* You can add global styles to this file, and also import other style files */
  html, body {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

/* CSS for NgxPaginationModule */
  .ngx-pagination ul { 
    margin: 0;
  }

  .ngx-pagination {
    text-align: center;
    margin-top: 30px !important;
    margin-bottom: 20px !important;
    transform: translate(-30px, 0);
  }
  
  .ngx-pagination .current {
    padding: 0.5rem 1.0rem !important;
    background-color: #1E2543 !important;
    border-radius: 10px !important;
  }

/* Snack Bar (Dialog box) */
  snack-bar-container {
    background: #1E2543 !important;
    border: 2px solid #1E2543;
    color: white !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

/* CSS for orderBy pipe */
  thead > tr > th {
    cursor: pointer;
  }

  .position-arrow { 
    position: relative;
    left: 50%;
    transform: translate(-100%, 0);
  }

/* Flex Modules */
  .fbx {
    display: flex !important;
    align-items: center;
  }

  .fbx-center { 
    justify-content: center;
  }

  .fbx-sp-btw {
    justify-content: space-between;
  }

/* New btn color */
  .stampel-color {
    background: #343A55 !important;
    color: white !important;
    transition: background-color 0.3s !important;
  }

  .stampel-color:hover {
    background-color: #8357bf !important;
    color: white !important;
  }

  .page-sidebar-menu a:hover { 
    background-color: rgb(154, 100, 228, 0.6) !important;
  }

  .sub-menu a:hover { 
    background-color: rgb(154, 100, 228, 0.6) !important;
  }
