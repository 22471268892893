@font-face {
    font-family: "Sansation_Bold";
	src: url(../../../fonts/Sansation_Bold.ttf);
}


/* HELPERS ------------------------------------------*/

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}


/* GENERAL ------------------------------------------*/

body {
    padding: 0;
}

.body-content {
    padding-top: 60px;
}

#body {
    min-height: 400px;
}

.navbar-brand {
    padding-left: 43px;
    font-size: 1.7em;
    font-family: Sansation_Bold;
    font-style: italic;
    color: #999999 !important;
    background-image: url(../../../images/favicon.png);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 35px;
}


/* PAGE CONTENT ------------------------------------------*/

#home-panel {
    padding: 120px 0 180px 0;
    text-align: center;
}

#home-panel #logo-home {
    width: 100%;
    height: 150px;
    background-image: url(../../../images/favicon.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

#home-panel > h1 {
    font-size: 7em;
    font-family: Sansation_Bold;
    font-style: italic;
    color: #999999;
    margin-top: 0;
}

/* METRONIC DASHBOARD */

.portlet.light.bordered > .portlet-title.receiver {
    border-bottom: 0px solid #eef1f5;
    margin-bottom: 0px;
}

.mt-element-list .list-default {
    min-height: 330px;
}

.mt-element-list .list-default.mt-list-container ul {
    margin-bottom: 0;
    min-height: 288px;
}

.mt-element-list .list-default.mt-list-container {
    padding-top: 0px;
    padding-right: 5px;
    padding-bottom: 0px;
    padding-left: 5px;
}

.mt-element-list .list-default.mt-list-container ul > .mt-list-item > .list-qtde {
    text-align: right;
    float: right;
}

.mt-element-list .list-default.mt-list-container ul > .mt-list-item:first-child {
    list-style: none;
    border-bottom: 1px solid;
    border-color: #e7ecf1;
    padding: 25px 0;
    min-height: 45px;
}

.mt-element-list .list-default.mt-list-container ul > .mt-list-item:last-child {
    list-style: none;
    border-color: #e7ecf1;
    padding: 25px 0;
    min-height: 45px;
}

.mt-element-list .list-default.mt-list-container ul > .mt-list-item:first-child {
    list-style: none;
    border-bottom: 1px solid;
    border-color: #e7ecf1;
    padding: 25px 0;
    min-height: 45px;
}

.mt-element-list .list-default.mt-list-container ul > .mt-list-item > .list-icon-container {
    border: 1px solid #e7ecf1;
    border-radius: 50% !important;
    padding: .9em;
    float: left;
    width: 35px;
    height: 35px;
    margin-left: 10px;
}

li.mt-list-item [class*=" icon-"], li.mt-list-item [class^="icon-"] {
    top: -5px;
    position: relative;
    left: -4px;
}

.mt-element-list .list-default.mt-list-container {
    padding-top: 0px;

    padding-bottom: 0px;

    margin-left: -5px;
    margin-right: -5px;
}

.mt-element-list .list-default.mt-list-container {
    padding-top: 0px;
    padding-right: 5px;
    padding-bottom: 0px;
    padding-left: 5px;
}


/* FOOTER ------------------------------------------*/

.footer-content {
    margin-top: 50px !important;
    padding: 20px 15px;
    border-top: 1px solid #e6e6e6;
}

/* Custom nordware ------ */

.label-primary {
  background-color: #495171;
  border-color: #101732
}

.btn-primary {
  background-color: #1E2543;
  border-color: #101732
}

  .btn-primary :hover {
    background-color: #2E3864;
  }

.pagination > li > a,
.pagination > li > span {
  color: #1E2543;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #1E2543;
  border-color: #101732
}
