/*
    bringIT pattern styles for Metronic theme

    Created at 2017-06-21 by Gustavo Matias
    Version 1.0
*/

/* COLORS ========================================================== */

.font-blue-bringit {
    color: #1E2543 !important;
}

.bg-blue-bringit {
    background-color: #1E2543 !important;
}

.font-greylight-bringit {
    color: #d6d6d6 !important;
}

.bg-greylight-bringit {
    background-color: #d6d6d6 !important;
}

.font-greydark-bringit {
    color: #ababab !important;
}

.bg-greydark-bringit {
    background-color: #ababab !important;
}

.font-orangelight-bringit {
    color: #f28200 !important;
}

.bg-orangelight-bringit {
    background-color: #f28200 !important;
}

.font-orangedark-bringit {
    color: #9d5400 !important;
}

.bg-orangedark-bringit {
    background-color: #9d5400 !important;
}

.font-greenlight-bringit {
    color: #00ff48 !important;
}

.bg-greenlight-bringit {
    background-color: #00ff48 !important;
}

.font-greendark-bringit {
    color: #00b233 !important;
}

.bg-greendark-bringit {
    background-color: #00b233 !important;
}


/* BOOTSTRAP COMPONENTS ========================================================== */

.btn-danger {
    background-color: #f28200;
    border-color: #ed6100;
}

    .btn-danger:hover,
    .btn-danger:focus {
        background-color: #ed6100;
        border-color: #ed6100;
    }

.btn-warning {
    background-color: #00b233;
    border-color: #00a122;
}

    .btn-warning:hover,
    .btn-warning:focus {
        background-color: #00a122;
        border-color: #00a122;
    }


/* LOGIN ========================================================== */

.login {
    background-color: #334455 !important;
}

    .login .logo {
        width: 300px;
        margin: 90px auto 20px auto;
        padding: 15px;
        text-align: center;
    }

        .login .logo img {
            width: 100%;
        }

    .login .content {
        background-color: #fff;
        width: 360px;
        margin: 0 auto;
        margin-bottom: 0px;
        padding: 30px;
        padding-top: 50px;
        padding-bottom: 40px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
        border-radius: 7px;
    }

        .login .content h3 {
            color: #000;
        }

        .login .content h4 {
            color: #555;
        }

        .login .content p {
            color: #222;
        }

        .login .content .login-form,
        .login .content .forget-form {
            padding: 0px;
            margin: 0px;
        }

        .login .content .input-icon {
            border-left: 2px solid #44B6AE !important;
        }

        .login .content .input-icon {
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -ms-border-radius: 4px;
            -o-border-radius: 4px;
            border-radius: 4px;
        }

            .login .content .input-icon .form-control {
                border-left: 0;
            }

        .login .content .forget-form {
            display: none;
        }

        .login .content .register-form {
            display: none;
        }

        .login .content .form-title {
            font-weight: 300;
            margin-bottom: 25px;
        }

        .login .content .form-actions {
            background-color: #fff;
            clear: both;
            border: 0px;
            border-bottom: 1px solid #eee;
            padding: 0px 30px 25px 30px;
            margin-left: -30px;
            margin-right: -30px;
        }

        .login .content .forget-form .form-actions {
            border: 0;
            margin-bottom: 0;
            padding-bottom: 20px;
        }

        .login .content .register-form .form-actions {
            border: 0;
            margin-bottom: 0;
            padding-bottom: 0px;
        }

        .login .content .form-actions .rememberme {
            margin-top: 8px;
            display: inline-block;
        }

        .login .content .form-actions .btn {
            margin-top: 1px;
        }

        .login .content .forget-password {
            margin-top: 25px;
        }

        .login .content .create-account {
            border-top: 1px dotted #eee;
            padding-top: 10px;
            margin-top: 15px;
        }

            .login .content .create-account a {
                display: inline-block;
                margin-top: 5px;
            }

        /* select2 dropdowns */
        .login .content .select2-container {
            border-left: 2px solid #44B6AE !important;
        }

            .login .content .select2-container .select2-choice {
                border-left: none !important;
            }

            .login .content .select2-container i {
                display: inline-block;
                position: relative;
                color: #ccc;
                z-index: 1;
                top: 1px;
                margin: 4px 4px 0px -1px;
                width: 16px;
                height: 16px;
                font-size: 16px;
                text-align: center;
            }

        .login .content .has-error .select2-container i {
            color: #b94a48;
        }

        .login .content .select2-container a span {
            font-size: 13px;
        }

            .login .content .select2-container a span img {
                margin-left: 4px;
            }

    /* footer copyright */
    .login .copyright {
        text-align: center;
        margin: 0 auto;
        padding: 10px;
        color: #999;
        font-size: 13px;
    }

@media (max-width: 480px) {
    /***
  Login page
  ***/
    .login .logo {
        margin-top: 10px;
    }

    .login .content {
        width: 280px;
    }

        .login .content h3 {
            font-size: 22px;
        }

    .login .checkbox {
        font-size: 13px;
    }
}


/* TOPBAR ========================================================== */

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-menu {
    width: auto;
    min-width: 190px;
}

.page-header.navbar .page-logo .logo-default {
    margin: 10px 0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle > i {
    color: #aeb2c4;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .details .label-icon {
    padding: 4px !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle > img {
    border: 1px solid #aeb2c4;
}

.dropdown-stampel { 
    border: 1px solid #ccc !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    border-radius: 2px;
}

.dropdown-stampel .user-identification { 
    height: 112px;
}

.dropdown-stampel .user-identification .picture {
    width: 96px;
    margin: 8px; 
    float: left;
}

.dropdown-stampel .user-identification .data {
    width: 150px;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 10px 0px 0px 10px;
}

.dropdown-stampel .user-identification .data .email {
    color: #666;
}

.dropdown-stampel .action { 
    padding: 10px;
    border-top: 1px solid #ccc;
    background: #f5f5f5;
    height: 60px;
}

.dropdown-stampel .action .btn-exit { 
    width: 100px;
    color: #666 !important;
}

/* Imagem usuário */
.xsuser {
    margin-top: 2px !important;
    height: 32px !important;
}



/* USER NOTIFICATIONS AT TOPBAR ========================================================== */

#user-notifications-dropdown > a > span.scale-down {
    transform: scale(.1);
}

#user-notifications-dropdown > a > span.scale-up {
    transform: scale(2);
    transition: all .2s;
}

#user-notifications-dropdown > a > span.scale-none {
    transform: scale(1);
    transition: all .25s;
}

/* NEW BRINGIT BLUE PATTERN ========================================================== */
.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle:hover {
    background-color: #1E2543 !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li:first-child a:hover {
    background-color: #1E2543 !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown.open .dropdown-toggle > a:hover {
    background-color: #1E2543 !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown.open .dropdown-toggle {
    background-color: #1E2543 !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a.bordermenu {
    border-bottom: 1px solid #646b89 !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle {
    padding: 21px 10px 18px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > .username {
    color: white;
    font-weight: 500;
}

ul.dropdown-menu.dropdown-menu-default {
    border-bottom-color: #1E2543 !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu {
    background: white !important;
    max-height: 200px;
    width: 280px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li.divider {
    background: #9198b1 !important;
    margin: 3px 0 !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li > a:hover {
    background-color: #fff  !important;
}

.login .content {
    padding-top: 20px !important;
    padding-bottom: 30px !important;
}

.bgimglogo {
    width: 65%;
    margin-bottom: 2%;
}

.bglogo {
    width: 20%;
    margin-bottom: 2%;
}

.print-bt {
    width: 90%;
    padding-top: 62px;
    height: 136px;
    margin-left: 10px;
}

.deleteable {
    width: unset !important;
    height: unset !important;
}

.accordion .panel .panel-title .accordion-toggle.accordion-toggle-styled.collapsedetails.collapsed {
    background-position: left 5px top 10px !important;
}

.accordion .panel .panel-title .accordion-toggle.accordion-toggle-styled.collapsedetails {
    background-position: left 5px top -20px !important;
    margin-right: 0px !important;
}

.accordion .panel .panel-title .accordion-toggle.collapsedetails {
    padding: 15px 12px !important;
}

pre.form-control.notdisabled {
    background-color: #f9f9f9 !important;
}

.table.table-bordered.lig {
    background-color: #f9f9f9 !important;
    margin-top: 10px;
}

pre {
    display: flex;    
    white-space: normal;
    word-break: break-word;
}

.yellowCell {
    background-color: #fffced;
}

.redCell {
    background-color: #ffeded;
}

.greyCell {
    background-color: #fcf6f6;
}

.blueCell {
    background-color: #ecf8ff;
}

.greenCell {
    background-color: #f2ffed;
}

.bg-blue-bringit {
    background-color: #1E2543 !important;
}

/* TEMPLATE ==================================================== */

.user-login-5 .login-container > .login-content {
    margin-top: 30%;
}

.login-description {
    font-size: 20px !important;
    color: dimgray !important;
}

.google-button {
    height: 48px;
    width: 192px;
    background: url("assets/images/btn-google-signin_dark_normal_web.png") no-repeat;
    box-shadow: unset !important;
}

.google-button:hover {
    height: 48px;
    width: 192px;
    background: url("assets/images/btn-google-signin_dark_focus_web.png") no-repeat;
    box-shadow: unset !important;
}

.google-button:focus {
    height: 48px;
    width: 192px;
    background: url("assets/images/btn-google-signin_dark_pressed_web.png") no-repeat;
    box-shadow: unset !important;
}

.img-cog {
    height: 20px !important;
    border: none !important;
}

/* Menu lateral */
.page-sidebar, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover {
  background-color: #1E2543 !important;
}

  .page-sidebar .page-sidebar-menu > li.active > a,
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a {
    border-left: 3px solid #2E3864 !important;
  }

  .page-sidebar .page-sidebar-menu > li.active.open > a,
  .page-sidebar .page-sidebar-menu > li.active > a,
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a,
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a {
    background: #2E3864;
    color: white;
  }

    .page-sidebar .page-sidebar-menu > li.active.open > a:hover,
    .page-sidebar .page-sidebar-menu > li.active > a:hover,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a:hover,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a:hover {
      background: #2E3864;
      color: white;
    }

.page-sidebar .page-sidebar-menu > li.active.open > a > .title,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .title {
    font-weight: 600;
}

.page-sidebar .page-sidebar-menu > li.active.open > a > i,
.page-sidebar .page-sidebar-menu > li.active > a > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > i {
    color: white;
}

.page-sidebar .page-sidebar-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
    color: white;
}

  .page-sidebar .page-sidebar-menu > li > a:hover,
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a:hover {
    background-color: #2E3864;
    color: white;
  }

.page-sidebar .page-sidebar-menu > li > a > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i {
    color: white;
}

.page-sidebar .page-sidebar-menu > li > a:hover > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a:hover > i {
    color: white;
}

.arrow {
    color: #b1c4d2 !important;
}

/*.page-sidebar .page-sidebar-menu > li > a > .arrow.open::before,
.page-sidebar .page-sidebar-menu > li > a > .arrow::before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow.open::before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow::before {
    color: #67a2d4 !important;
}*/

.page-sidebar .page-sidebar-menu li > a > .arrow.open::before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow.open::before {
    color: #67a2d4 !important;
}

.page-sidebar .page-sidebar-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
    padding: 6px 15px;
}

.page-sidebar .page-sidebar-menu > li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li {
    margin-bottom: 10px;
}

  .page-sidebar .page-sidebar-menu > li.open > a,
  .page-sidebar .page-sidebar-menu > li:hover > a,
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a,
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a {
    background: #2E3864;
    color: white;
  }

.page-sidebar .page-sidebar-menu > li.open > a > i,
.page-sidebar .page-sidebar-menu > li:hover > a > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > i {
    color: white;
}

.page-sidebar .page-sidebar-menu .sub-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a {
    color: white;
    padding: 6px 15px;
}

  .page-sidebar .page-sidebar-menu .sub-menu > li > a:hover,
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a:hover {
    color: white;
    background-color: #2E3864 !important;
    padding: 6px 15px;
  }

.page-sidebar .page-sidebar-menu .sub-menu li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li {
    margin-bottom: 10px;
}

.page-sidebar .page-sidebar-menu .sub-menu > li.active > a,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a {
  color: white;
  background: #2E3864 !important;
}

.page-header.navbar .menu-toggler {
    background-image: url("/assets/MetronicTheme/layout/img/sidebar-toggle-light.png");
}

.page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a {
    min-height: 0px;
}

.page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.open > .sub-menu,
.page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > .sub-menu {
  background-color: #1E2543;
}

@media screen and (max-width:640px) {
    .page-header.navbar .page-logo .logo-default {
        margin-top: 5px !important;
        margin-left: 10px !important;
    }

    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle > img.img-cog {
        margin-top: 6px;
    }

    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle > i {
        margin-top: 8px;
    }

    .xsdropconfig {
        min-height: 33px;
    }

    .xsdropicon {
        margin-left: 10px !important;
    }

    .page-sidebar .page-sidebar-menu > li,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li {
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }

    .page-sidebar .page-sidebar-menu > li > a,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
        padding: 8px 15px !important;
    }

    .page-sidebar .page-sidebar-menu .sub-menu li,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li {
        margin-bottom: 0px !important;
    }

    .page-sidebar .page-sidebar-menu .sub-menu,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu {
        margin: 4px 0 !important;
    }

    .page-sidebar .page-sidebar-menu,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
        margin: 10px 0 !important;
    }

    .xsmenuser {
        display: none !important;
    }
}

.xsdropconfig {
    padding: 10px;
    height: auto;
}


/* New config to do the LOG-612 task needs */
.portlet-title .caption > small {
    color: #9eacb4;
    font-size: 13px;
    font-weight: 400;
    margin-top: 5px !important;
}

.report .portlet-title {
    background-color: white !important;    
    border: none !important;
    padding-bottom: 0 !important;
}

.list-filter {
    background: rgb(30, 37, 67, 0.2);
    margin: 0 0 30px 0;
    padding: 15px 20px 10px 20px;
}

/* Overwrite bootstrap css - PAGE FOOTER  */
.stampel-page-footer {
    position: relative;
    width: 100%;
    bottom: 0; 
    padding: 10px 10px !important;
    margin: 0;
}

.stampel-page-footer .info { 
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
}
